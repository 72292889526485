.mediaplayer-container {
    /* We first create a flex layout context */
    display: flex;
    

    
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .item-container {
    /* We first create a flex layout context */
    display: flex;
    

    
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .label-item {
    padding: 9px 2px 5px 2px;
    width: 130px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .mediaplayer {
    flex-grow: 1;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .mediaplayer-item {
    padding: 9px 5px 5px 5px;
    flex-grow: 1;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .link-item {
    display: flex;
  justify-content: center;
  align-items: center;
    padding: 5px;
    width: 100px;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .volume-icon {
    padding: 0px 15px 0px 15px;
  }
  .volume-slider {
    padding: 0px 0px 0px 0px;
  }
  .icon-button-item {
    cursor:pointer;
    display: flex;
  justify-content: center;
  align-items: center;
    padding: 5px;
    width: 50px;
    color: white;
    font-weight: bold;
  }

  .button-item {
    cursor:pointer;
    display: flex;
  justify-content: center;
  align-items: center;
    padding: 5px;
    width: 100px;
    color: white;
    font-weight: bold;
  }
  .active{
background-color: #5c5c5c;
  }

  .link-item a {

    color: white;
    text-align: center;
}

.button-item a {

    color: white;
    text-align: center;
}

  @media only screen and (max-width: 767px) {

    .button-item {

        width: 40px;
        color: white;

      }
      .label-item {
        width: 50px;
        color: white;
      }
}

i.icon {
    font-size: inherit;
} 